<template>
  <div class="px-4">
    <b-card class="page-card">
      <div slot="header">
        <b-row>
          <b-col cols="12">
            <div class="page-header-left">
              <div class="pr-1 header-icon">
                <i class="cuis-terminal card-icon-font"></i>
              </div>
              <div>
                <h5 class="mb-0"><b>FIN Technographics</b></h5>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-row>
        <b-col cols="12 pb-3">
          <p>
            Technographics yield insight into the technologies deployed by
            financial institutions. Enhance your account-based marketing (ABM)
            by identifying those institutions with competitive or complementary
            technologies. Additionally, you can discover those financial
            institutions with an absence of a deployed solution. Once a target
            list is generated you can filter the institutions through
            firmographics (ex. size or type). Try it out now.
          </p>
        </b-col>
      </b-row>

      <b-row class="search-section justify-content-center">
        <b-col sm="10">
          <div class="search-bar">
            <b-input-group>
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-search fa-lg"></i>
                </span>
              </div>
              <b-form-input
                v-focus
                id="prependedInput"
                class="form-control no-focus-highlt"
                size="lg"
                type="text"
                autocomplete="off"
                placeholder="Enter a solution type or a specific vendor or product name"
                v-model="searchText"
              >
              </b-form-input>
            </b-input-group>
          </div>

          <div class="search-result std-border" v-if="showResult">
            <b-card-group>
              <b-card class="right-border vendor-result" body-class="p-0">
                <div>
                  <b-row class="no-gutters pt-4 px-3">
                    <b-col sm="6">
                      <h6 class="mb-0"><b>VENDOR PRODUCTS</b></h6>
                    </b-col>
                    <b-col sm="6">
                      <router-link
                        :to="{ name: 'VendorCoverage' }"
                        tag="div"
                        class="cursor-pointer text-right"
                        ><b
                          ><u><h6>View All Vendors</h6></u></b
                        ></router-link
                      >
                    </b-col>
                  </b-row>
                  <b-list-group class="selectable-list-group pl-3 pr-2">
                    <v-wait for="fetchingTechnographicsData">
                      <template slot="waiting">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="10"
                          ></content-placeholders-text>
                        </content-placeholders>
                      </template>

                      <div v-if="emptyVendorProducts">
                        <b-list-group-item
                          class="text-center pt-4 pb-4 empty-result"
                        >
                          <div class="p-2">
                            <h6 class="mb-0">
                              <i> No matching Vendors/Products </i>
                            </h6>
                          </div>
                        </b-list-group-item>
                      </div>

                      <div
                        v-else
                        v-for="(vendor, index) in this.vendorProducts"
                        :key="index"
                      >
                        <div class="d-flex mt-2">
                          <header-logo
                            logoType="vendor"
                            :logoId="vendor.id"
                            :logoUrl="vendorLogoUrl(vendor)"
                            class="justify-content-center"
                          ></header-logo>

                          <router-link
                            class="list-group-item list-group-item-action pt-2 pb-1 px-0"
                            :to="{
                              path: '/technographics/vendor_report/' + vendor.id
                            }"
                          >
                            {{ vendor.name }}
                          </router-link>
                        </div>
                        <router-link
                          v-for="(product, index) in vendor.products"
                          :key="index"
                          class="list-group-item list-group-item-action p-1 pl-5"
                          :to="{
                            path:
                              '/technographics/product_report/' + product.id,
                            query: {
                              vendor_id: vendor.id,
                              bank_service_id: product.bank_service_id
                            }
                          }"
                        >
                          {{ product.name }}
                        </router-link>
                      </div>

                      <div v-if="this.enableShowMoreVendors">
                        <b-list-group-item
                          class="text-right"
                          v-if="this.showMoreVendorProducts"
                          @click="increaseLimit('vendor')"
                        >
                          <b
                            ><i
                              ><u
                                ><h6>
                                  ...and {{ remainingVendorProducts }} more
                                </h6></u
                              ></i
                            ></b
                          >
                        </b-list-group-item>
                        <b-list-group-item
                          class="text-right"
                          v-else
                          @click="decreaseLimit('vendor')"
                        >
                          <b
                            ><i
                              ><u><h6>...View Less</h6></u></i
                            ></b
                          >
                        </b-list-group-item>
                      </div>
                    </v-wait>
                  </b-list-group>
                </div>
              </b-card>

              <b-card class="left-border solution-result" body-class="p-0">
                <div>
                  <b-row class="no-gutters pt-4 px-3">
                    <b-col sm="6">
                      <h6 class="mb-0"><b>SOLUTIONS</b></h6>
                    </b-col>
                    <b-col sm="6">
                      <router-link
                        :to="{ name: 'Solutions' }"
                        tag="div"
                        class="cursor-pointer text-right"
                        ><b
                          ><u><h6>View All Solutions</h6></u></b
                        ></router-link
                      >
                    </b-col>
                  </b-row>

                  <b-list-group class="selectable-list-group pl-3 pr-2">
                    <v-wait for="fetchingTechnographicsData">
                      <template slot="waiting">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="10"
                          ></content-placeholders-text>
                        </content-placeholders>
                      </template>

                      <div v-if="emptyServices">
                        <b-list-group-item
                          class="text-center pt-4 pb-4 empty-result"
                        >
                          <div class="p-2">
                            <h6 class="mb-0"><i>No matching Solutions</i></h6>
                          </div>
                        </b-list-group-item>
                      </div>

                      <router-link
                        v-for="(solution, index) in this.services"
                        :key="index"
                        class="list-group-item list-group-item-action py-2 pr-1 pl-2"
                        :to="{
                          path: '/technographics/service_report/' + solution.id
                        }"
                      >
                        {{ solution.name }}
                      </router-link>

                      <div v-if="this.enableShowMoreServices">
                        <b-list-group-item
                          class="text-right"
                          v-if="this.showMoreServices"
                          @click="increaseLimit('service')"
                        >
                          <b
                            ><i
                              ><u
                                ><h6>...and {{ remainingServices }} more</h6></u
                              ></i
                            ></b
                          >
                        </b-list-group-item>
                        <b-list-group-item
                          class="text-right"
                          v-else
                          @click="decreaseLimit('service')"
                        >
                          <b
                            ><i
                              ><u><h6>...View Less</h6></u></i
                            ></b
                          >
                        </b-list-group-item>
                      </div>
                    </v-wait>
                  </b-list-group>
                </div>
              </b-card>
            </b-card-group>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
// global
import _ from 'lodash'
// ui components
import HeaderLogo from '@/modules/core/components/HeaderLogo'

export default {
  name: 'Search',
  components: {
    HeaderLogo
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      document.title = 'FIN Technographics'
      if (vm.$route.query.term) {
        vm.search()
      }
    })
  },
  data() {
    return {
      searchText: '',
      vendorProductsList: [],
      serviceList: [],
      enableShowMoreVendors: false,
      enableShowMoreServices: false,
      latestTimestamp: null,
      defaultVendorLimit: 5,
      defaultServiceLimit: 7,
      vendorLimit: 0,
      serviceLimit: 0,
      showResult: false
    }
  },
  created() {
    this.searchText = this.$route.query.term || ''
    this.vendorLimit = this.defaultVendorLimit
    this.serviceLimit = this.defaultServiceLimit
    this.debouncedSearch = _.debounce(this.search, 500)
  },
  computed: {
    resultHeight() {
      return window.innerHeight - 200
    },
    emptyVendorProducts() {
      return this.vendorProductsList.length === 0
    },
    emptyServices() {
      return this.serviceList.length === 0
    },
    remainingVendorProducts: function() {
      return this.vendorProductsList.length - this.defaultVendorLimit
    },
    remainingServices: function() {
      return this.serviceList.length - this.defaultServiceLimit
    },
    showMoreVendorProducts: function() {
      return this.vendorLimit === this.defaultVendorLimit
    },
    showMoreServices: function() {
      return this.serviceLimit === this.defaultServiceLimit
    },
    vendorProducts: function() {
      let productsList = []
      let limitedProducts = _.take(this.vendorProductsList, [this.vendorLimit])
      let groupedProducts = _.groupBy(limitedProducts, 'vendor')

      _.forEach(groupedProducts, function(products) {
        let mappedProducts = _.map(products, function(product) {
          return {
            id: product.product_id,
            name: product.product,
            bank_service_id: product.bank_service_id
          }
        })
        productsList.push({
          id: products[0].id,
          name: products[0].vendor,
          products: mappedProducts
        })
      })
      return productsList
    },
    services: function() {
      return _.take(this.serviceList, [this.serviceLimit])
    }
  },
  methods: {
    search: function() {
      if (this.searchText !== '') {
        this.$wait.start('fetchingTechnographicsData')
        this.latestTimestamp = new Date().getTime()
        this.showResult = true
        return this.$http
          .get('/api/search', {
            params: { term: this.searchText, time_stamp: this.latestTimestamp },
            handleErrors: false
          })
          .then(
            response => {
              this.latestTimestamp == response.data.result.time_stamp
              if (response.data.result.time_stamp == this.latestTimestamp) {
                this.resetLimit()
                this.serviceList = response.data.result.services
                this.vendorProductsList = response.data.result.vendor_products
                this.enableShowMoreVendors =
                  this.vendorProductsList.length > this.vendorLimit
                this.enableShowMoreServices =
                  this.serviceList.length > this.serviceLimit
                this.$wait.end('fetchingTechnographicsData')
              }
            },
            () => {
              this.$wait.end('fetchingTechnographicsData')
            }
          )
      } else {
        this.showResult = false
      }
    },
    increaseLimit(type) {
      if (type === 'service') {
        this.serviceLimit = 1000
      } else if (type === 'vendor') {
        this.vendorLimit = 1000
      }
    },
    decreaseLimit(type) {
      if (type === 'service') {
        this.serviceLimit = this.defaultServiceLimit
      } else if (type === 'vendor') {
        this.vendorLimit = this.defaultVendorLimit
      }
    },
    resetLimit() {
      this.vendorLimit = this.defaultVendorLimit
      this.serviceLimit = this.defaultServiceLimit
    },
    vendorLogoUrl(vendor) {
      return `https://finapps-vendor-logos.s3-us-west-2.amazonaws.com/logo_${vendor.id}.png`
    }
  },
  watch: {
    searchText: function(newVal) {
      window.history.replaceState(
        {},
        'Fintech - Product Search',
        'search?term=' + newVal
      )
      this.debouncedSearch()
    }
  }
}
</script>

<style lang="scss" scoped>
.search-section {
  .search-result {
    border-top: none;
    font-size: 14px;

    .vendor-result {
      max-height: 20rem;
      overflow: auto;
    }

    .solution-result {
      max-height: 20rem;
      overflow: auto;
    }
  }

  .empty-result {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }

  .right-border {
    border-right: 1px solid #c2cfd6;
  }

  .left-border {
    border-left: 1px solid #c2cfd6;
  }
}
</style>
